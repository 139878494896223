import { asRouteMap } from '@kaliber/routing'

import typographyContext from '/cssGlobal/typography-context.css'
import styleContext from '/cssGlobal/style-context.css'

const articles = [
  {
    id: 'blog5',
    path: 'what-it-takes-to-join-aston-martin-aramco-formula-one-team',
    title: 'What It Takes to Join Aston Martin Aramco Formula One® Team: The Qualities That Drive Success',
    summary: 'What does it really take to work for a Formula 1® team? As the Global Talent Matching Partner of Aston Martin Aramco Formula One® Team, it’s been our job to find out'
  },
  {
    id: 'blog4',
    path: 'circle8s-role-as-global-talent-matching-partner',
    title: "Accelerating Excellence: Circle8's Role as Global Talent Matching Partner for Aston Martin Aramco Formula One® Team",
    summary: 'Last month, we proudly announced our exciting partnership with the Aston Martin Aramco Formula One® Team as their Global Talent Matching Partner.'
  },
  {
    id: 'blog3',
    path: 'aston-martin-aramco-formula-one-teamfindsperfectmatchwith-circle-8',
    title: 'Aston Martin Aramco Formula One® Team finds perfect match with Circle8',
    summary: 'Circle8 joins the team as Global Talent Matching Partner and will help Aston Martin Aramco find the best talent to compete at the front of the grid.'
  },
  {
    id: 'blog2',
    path: 'pascal-meerwijk-new-managing-director-seven-stars',
    title: 'Pascal Meerwijk New Managing Director Seven Stars',
    summary: 'Effective immediately, Pascal Meerwijk will start as Managing Director of Seven Stars, one of the labels of the international HR Tech services provider Circle8Group. Meerwijk will form the Seven Stars management team together with Corianna Geersing (CPO) and Mike Verheijen (CFO).'
  },
  {
    id: 'blog1',
    path: 'turnover-breaks-through-one-billion-euro-mark',
    title: 'Turnover Breaks Through One Billion Euro Mark',
    summary: 'International HR Tech service provider Circle8Group grew significantly last year. All five of the European companies in Circle8Group grew organically, increasing the 2023 turnover more than 20 per cent to 1.01 billion euros.'
  },
]

export const routeMap = asRouteMap({
  root: '',
  home: getDefaultLightPage({ path: 'home', title: '' }),
  services: getDefaultLightPage({ path: 'services', title: 'Services' }),
  aboutUs: getDefaultLightPage({ path: 'about', title: 'About us' }),
  careers: getDefaultLightPage({ path: 'careers', title: 'Careers' }),
  investors: getDefaultLightPage({ path: 'investors', title: 'Investors' }),

  newsroom: {
    ...getDefaultLightPage({ path: 'newsroom', title: 'Newsroom', articles }),
    ...getDetailPageByAvailableKeys(articles)
  },

  // These details pages are all the same, except path and title.
  find: getDetailPageObject({ path: 'find', title: 'We **find** talent' }),
  engage: getDetailPageObject({ path: 'engage', title: 'We **engage** talent' }),

  collab: {
    path: 'astonmartinf1',
    data: {
      domain: 'formula1',
      title: 'Aston Martin F1',
      styleContext: styleContext.contextDark,
      typographyContext: typographyContext.contextDark
    }
  },
  pressRelease: {
    path: 'pressrelease-amf1',
    data: {
      domain: 'formula1',
      title: 'Press release',
      styleContext: styleContext.contextDark,
      typographyContext: typographyContext.contextDark
    }
  },
  privacy: {
    path: 'privacy-statement',
    data: {
      title: 'Privacy statement',
      styleContext: styleContext.contextLight,
      typographyContext: typographyContext.contextDark
    }
  },
  notFound: {
    path: '*',
    data: {
      status: 404,
      title: '404',
      styleContext: styleContext.contextLight,
      typographyContext: typographyContext.contextLight
    }
  }
},
{ trailingSlash: true })

function getDetailPageObject({ path, title }) {
  return {
    path,
    data: {
      title,
      styleContext: styleContext.contextDark,
      typographyContext: typographyContext.contextLight
    }
  }
}

function getDefaultLightPage({ path, title, ...rest }) {
  return {
    path,
    data: {
      title,
      styleContext: styleContext.contextLight,
      typographyContext: typographyContext.contextLight,
      ...rest
    }
  }
}

function getDetailPageByAvailableKeys(source) {
  return source.reduce(
    (result, x) => ({ ...result, [x.id]: getNewsDetailPage(x) }),
    {}
  )
}

function getNewsDetailPage({ path, title, summary }) {
  return {
    path,
    data: {
      title,
      summary,
      styleContext: styleContext.contextLight,
      typographyContext: typographyContext.contextLight,
    }
  }
}
